import React from 'react';

const InstagramDelete = () => {
  return (
    <div>
      <h1>Instagram Data Deletion</h1>
      <p>Status: 200 OK</p>
      <p>Your Instagram data deletion requestion has been successfully submitted.</p>
    </div>
  );
};

export default InstagramDelete; 